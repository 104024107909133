<script>
    import Button from './Button.svelte';
    import Modal from './Modal.svelte';
    import { paddle_purchase } from './utils/paddle';
    import { logEvent } from './utils/logs';
    import { goto } from '$app/navigation';
    import { discount_data } from './stores/globals';
    import { page } from '$app/stores';
    import { browser } from '$app/environment';

    let session = $page.data.session;

    /** @type {Course} */
    export let course;

    export let hidden = false;
    export let feature = 'generic';
    export let stopPropagation = false;

    let className = '';
    export { className as class };

    export const show = () => {
        handle_modal_show();
    };

    export let loading = false;

    let show_error = false;

    const success_cb = (event) => {
        document.removeEventListener('paddle_success', success_cb);
        document.removeEventListener('paddle_close', close_cb);

        const data = event.detail;
        logEvent('purchase:success', { course: course.slug, feature });
        show_error = false;

        loading = false;

        goto(
            `/purchase-success?redirectTo=${location.origin}/courses/${course.slug}?poll=true`
        );
        
    };

    const close_cb = (event) => {
        document.removeEventListener('paddle_success', success_cb);
        document.removeEventListener('paddle_close', close_cb);
        const data = event.detail;
        console.log('close gift', course.slug, data);
        loading = false;
        logEvent('purchase:close', { course: course.slug, feature });
    };

    const handle_purchase = (e) => {
        if (stopPropagation) e.stopPropagation();
        loading = true;
        // console.log('handle_purchase', session);
        logEvent('purchase:show', { course: course.slug, feature });

        document.addEventListener('paddle_success', success_cb);
        document.addEventListener('paddle_close', close_cb);

        paddle_purchase({
            price_id: course.price_gift.price_id,
            discount_id: $discount_data.active
                ? $discount_data?.discount_id || null
                : null,
            email: session?.user?.email || null
        });
    };

    const handle_modal_show = () => {
        handle_purchase();
    };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
{#if !hidden}
    <div on:click={handle_modal_show} class="relative {className}">
        <slot>
            <Button
                primary
                outline
                lg
                class="h-full"
                block
                loading={!browser || loading}
                loading_spinner={browser}
            >
                <span class="text-3xl">🎁</span>
                <svelte:fragment slot="small">Buy as a gift</svelte:fragment>
            </Button>
        </slot>
    </div>
{/if}

{#if show_error}
    <Modal on:close={() => (show_error = false)}>
        <h1>Something went wrong</h1>
        <p>We could not process your purchase automatically</p>
        <p>
            Please contact us via <a href="mailto:support@critikid.com"
                >support@critikid.com</a
            > to restore your purchase
        </p>
    </Modal>
{/if}
